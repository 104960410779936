import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
// import * as ROUTES from '../../constants/routes';
import { withAuthorization, AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
// import BuyModal from '../BuyModal'
// import BuyWeeklyModal from '../BuyWeeklyModal'
// import DonateModal from '../DonateModal'

// import RaveModal from '../RaveModal'
// import RaveWeekModal from '../RaveWeekModal'
// import RaveDonateModal from '../RaveDonateModal'
// import RaveVerify from '../RaveVerify'

// class HomePageView extends Component {
//   constructor(props) {
//     super(props);
//   }

//   render() {
//     const { authUser, state } = this.props;
//     console.log('AuthUser: ' + authUser + ' State: ' + state);

//     return (
//       <div>
//         <h1>Home Page</h1>
//         <p>The Home Page is accessible by every signed in user.</p>
//         <h1>Account Email: {authUser.email}</h1>
//         <h1>Account ID: {state.userID}</h1>
//         <p>isPendingDay: {state.isPendingDay}</p>
//         <p>isPendingWeek: {state.isPendingWeek}</p>
//         <p>isPendingDonate: {state.isPendingDonate}</p>
//         {state.isPendingDay ? <button type="button" onClick={this.alertFunc}>Click Here To Confirm Payment For Today's Full Forecast</button> : <button type="button"><Link to={ROUTES.BUY_DAY}>Buy Today's Full Forecast</Link></button>}
//         {state.isPendingWeek ? <button type="button" onClick={this.alertFunc}>Click Here To Confirm Payment For Weekly Forecast</button> : <button type="button"><Link to={ROUTES.BUY_WEEK}>Subscribe to Weekly Forecast</Link></button>}
//         {state.isPendingMonth ? <button type="button" onClick={this.alertFunc}>Click Here To Confirm Donation to Lotto Kiosk</button> : <button type="button"><Link to={ROUTES.DONATE}>Donate to Lotto Kiosk</Link></button>}
//       </div>
//     )
//   }
// };


class HomePage extends Component {
  constructor(props) {
    super(props);
    // this.alertFunc = this.alertFunc.bind(this);
    // this.confirmPayment = this.confirmPayment.bind(this);
    this.confirmDay = this.confirmDay.bind(this);
    this.confirmWeekly = this.confirmWeekly.bind(this);
    this.confirmDonate = this.confirmDonate.bind(this);
    this.deleteAlert = this.deleteAlert.bind(this);

    this.state = {
      loading: false,
      users: {},
      nums: {},
      // isPendingDay: this.props.context.isPendingDay || false,
      // isPendingWeek: this.props.context.isPendingWeek || false,
      // isPendingDonate: this.props.context.isPendingDonate || false,
      // isPendingDay: true,
      answeer: "",
    };
  }

  alertFunc = () => {
    alert('Clicked Properly!!!')
  }

  confirmDay() {
    this.props.firebase.confirmDay()
      .then(function (result) {
        if (!result) alert("Error: There was an error");
        var serverResponse = result.data.res;
        alert("Purchase Notice  " + serverResponse);
      })
      .catch(error => {
        var message = error.message;
        var sentence = message;
        console.log(error)
        alert("Purchase Error   " + sentence);
      })
  }

  confirmWeekly() {
    this.props.firebase.confirmWeekly()
      .then(function (result) {
        if (!result) alert("Error: There was an error");
        var serverResponse = result.data.res;
        alert("Purchase Notice  " + serverResponse);
      })
      .catch(error => {
        var message = error.message;
        var sentence = message;
        console.log(error)
        alert("Purchase Error   " + sentence);
      })
  }

  confirmDonate() {
    this.props.firebase.confirmDonate()
      .then(function (result) {
        if (!result) alert("Error: There was an error");
        var serverResponse = result.data.res;
        alert("Purchase Notice  " + serverResponse);
      })
      .catch(error => {
        var message = error.message;
        var sentence = message;
        console.log(error)
        alert("Purchase Error   " + sentence);
      })
  }

  // DeleteUserButton = ({ firebase }) => ();

  deleteAlert() {
    // let answer= prompt("Ow,...why..?");
    // this.setState({
    //   answeer: answer
    // })
    if (window.confirm('Are you sure you want to Delete your Account from LottoKiosk servers?\nDeleting cannot be reversed!')) {
      // Save it!
      this.props.firebase.doDeleteUser();
      alert('Bye bye.');
      // alert('Thing was saved to the database.');
    } else {
      // Do nothing!
    alert('Good Choice')
      // alert('Thing was not saved to the database.');
    }
  }

  // confirmPayment = type => {
  //   var confirm;
  //   if (type === 'day') {
  //   }
  //   if (type === 'week') {
  //     this.props.firebase.confirmWeekly()
  //       .then(function (result) {
  //         if (!result) alert("Error: " + "There was an error");
  //         var serverResponse = result.data.res;
  //         alert("Purchase Notice  " + serverResponse);
  //       })
  //       .catch(error => {
  //         var code = error.code;
  //         var message = error.message;
  //         var details = error.details;
  //         var sentence = message;
  //         console.log(error)
  //         alert("Purchase Error   " + sentence);
  //       })
  //   }
  //   if (type === 'donate') {
  //     console.log('clickity');

  //     this.props.firebase.confirmDonate()
  //       .then(function (result) {
  //         if (!result) alert("Error: " + "There was an error");
  //         var serverResponse = result.data.res;
  //         alert("Purchase Notice  " + serverResponse);
  //       })
  //       .catch(error => {
  //         var code = error.code;
  //         var message = error.message;
  //         var details = error.details;
  //         var sentence = message;
  //         console.log(error)
  //         alert("Purchase Error   " + sentence);
  //       })
  //   }
  //   // this.props.firebase.confirm(type)
  //   //   .then(function (result) {
  //   //     if (!result) alert("Error: " + "There was an error");
  //   //     var serverResponse = result.data.res;
  //   //     alert("Purchase Notice  " + serverResponse);
  //   //   })
  //   //   .catch(error => {
  //   //     var code = error.code;
  //   //     var message = error.message;
  //   //     var details = error.details;
  //   //     var sentence = message;
  //   //     console.log(error)
  //   //     alert("Purchase Error   " + sentence);
  //   //   });
  // }

  componentDidMount() {
    this.setState({ userID: this.props.firebase.uid() })

    this.props.firebase.pendingDayConfirm(this.props.firebase.uid()).on('value', snapshot => {
      this.setState({
        isPendingDay: snapshot.val() ? true : false,
      })
    });
    this.props.firebase.pendingWeekConfirm(this.props.firebase.uid()).on('value', snapshot => {
      this.setState({
        isPendingWeek: snapshot.val() ? true : false,
      })
    });
    this.props.firebase.pendingDonateConfirm(this.props.firebase.uid()).on('value', snapshot => {
      this.setState({
        isPendingDonate: snapshot.val() ? true : false,
      })
    });

    // this.setState({
    //   isPendingDay: this.props.firebase.pendingDayConfirm(this.props.firebase.uid()) ? true : false,
    //   isPendingWeek: this.props.firebase.pendingWeekConfirm(this.props.firebase.uid()) ? true : false,
    //   isPendingDonate: this.props.firebase.pendingDonateConfirm(this.props.firebase.uid()) ? true : false
    // })
    // console.log("State=> " + this.props.firebase.pendingDayConfirm(this.props.firebase.uid()))

  }

  componentWillUnmount() {
    this.props.firebase.pendingDayConfirm().off();
    this.props.firebase.pendingWeekConfirm().off();
    this.props.firebase.pendingDonateConfirm().off();
  }

  render() {

    // const DeleteUserButton = ({ firebase }) => (
    //   <button
    //   type="button"
    //   // onClick={firebase.doDeleteUser}
    //   onClick={window.confirm('Are you sure you want to save this thing into the database?') ?
    //   alert('Bye bye.').then(firebase.doDeleteUser) :
    //     // alert('Bye bye.') :
    //     alert('Good Choice')
    //    }
    //   style={{ margin:7, backgroundColor:"orange" }}>
    //     Delete This Account and all its data!
    //   </button>
    // );
    
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          // <HomePageView authUser={authUser} state={this.state} />

          // <div> //Old view w/ subscriptions before it miraculously¿ stopped working
          //   <h1>Home Page</h1>
          //   <h1>Account Email: {authUser.email}</h1>
          //   {/* <h1>Account ID: {this.state.userID}</h1> */}
          //   <p>Steps to Subscribe</p>
          //   <ol>
          //     <li>Click corresponding button to begin purchase</li>
          //     <li>Enter the information required and click 'Submit'</li>
          //     <li>Follow the instructions to complete payment with your Mobile Money Account(Your browser may block a popup. Please follow that link)</li>
          //     <li>After payment is completed with your Momo Account, you now have to confirm payment here on this page or <a href="https://lottokiosk.page.link/?link=https%3A%2F%2Flottokioskgh%2Ecom&ibi=com%2Eroyceinitiative%2ELottoKiosk%22&apn=com%2Eroyceinitiative%2Elottokiosk">in the app.</a></li>
          //     <li>After confirming payment, the app would reload to your new Subscription package</li>
          //   </ol>
          //   {/* <RaveVerify /> */}
          //   {this.state.isPendingDay ? <button type="button" style={{ margin:7 }} onClick={this.confirmDay}>Click Here To Confirm Payment For Today's Full Forecast</button> : <RaveModal email={authUser.email} />}
          //   {/* {this.state.isPendingDay ? <button type="button" onClick={this.confirmDay}>Click Here To Confirm Payment For Today's Full Forecast</button> : <BuyModal />} */}
          //   {/* {this.state.isPendingWeek ? <button type="button" onClick={this.confirmWeekly}>Click Here To Confirm Payment For Weekly Forecast</button> : <BuyWeeklyModal />}
          //   {this.state.isPendingDonate ? <button type="button" onClick={this.confirmDonate}>Click Here To Confirm Donation to Lotto Kiosk</button> : <DonateModal />} */}
          //   {this.state.isPendingWeek ? <button type="button" style={{ margin:7 }} onClick={this.confirmWeekly}>Click Here To Confirm Payment For Weekly Forecast</button> : <RaveWeekModal email={authUser.email} />}
          //   {this.state.isPendingDonate ? <button type="button" style={{ margin:7 }} onClick={this.confirmDonate}>Click Here To Confirm Donation to Lotto Kiosk</button> : <RaveDonateModal email={authUser.email} />}
          // </div>
          <div>
             <h1>Home Page</h1>
             <h1>Account Email: {authUser.email}</h1>
             {/* <h1>Account ID: {this.state.userID}</h1> */}
             <p>Subscription is hereby suspended until further notice due to service interruptions</p>
             {this.state.isPendingDay && <button type="button" style={{ margin:7 }} onClick={this.confirmDay}>Click Here To Confirm Payment For Today's Full Forecast</button>}
             {this.state.isPendingWeek && <button type="button" style={{ margin:7 }} onClick={this.confirmWeekly}>Click Here To Confirm Payment For Weekly Forecast</button>}
             {this.state.isPendingDonate && <button type="button" style={{ margin:7 }} onClick={this.confirmDonate}>Click Here To Confirm Donation to Lotto Kiosk</button>}

             <button type="button" style={{ margin:7, backgroundColor:"orange" }} onClick={this.deleteAlert}>Delete My Account</button>
          
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}


const condition = authUser => !!authUser;
export default withAuthorization(condition)(withFirebase(HomePage));