import React from 'react';

const App = () => (
  <div>
    <h1>Landing Page</h1>
    <p>Sign In For Subscriptions</p>
    <p><a href='https://play.google.com/store/apps/details?id=com.royceinitiative.lottokiosk'>Download Lotto Kiosk For Android</a></p>
    <p><a href='https://apps.apple.com/us/app/lotto-kiosk/id1450039870?ls=1'>Download Lotto Kiosk For iOS</a></p>
    <a href="https://lottokiosk.page.link/?link=https%3A%2F%2Flottokioskgh%2Ecom&ibi=com%2Eroyceinitiative%2ELottoKiosk%22&apn=com%2Eroyceinitiative%2Elottokiosk"> Open Lotto Kiosk</a>
    <h4>Privacy Policy</h4>
    <h5>The data we collect will only be used to create user accounts. Unless otherwise stated, no user details, pictures or content uploaded to the app will be saved our servers. Lotto Kiosk</h5>
  </div>
);

export default App;