export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const PASSWORD_FORGET = '/pw-forget';

export const BUY_DAY = '/daily';
export const BUY_WEEK = '/weekly';
export const DONATE = '/donate';