import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/functions';

const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID
};

class Firebase {
    constructor() {
        app.initializeApp(config);
        this.auth = app.auth();
        this.db = app.database();
        this.func = app.functions();
        // app.functions().useFunctionsEmulator("http://localhost:5001");
    }
    // *** Auth API ***
    doCreateUserWithEmailAndPassword = (email, password) =>
        this.auth.createUserWithEmailAndPassword(email, password);

    doSignInWithEmailAndPassword = (email, password) =>
        this.auth.signInWithEmailAndPassword(email, password);

    doSignOut = () => this.auth.signOut();

    doDeleteUser = () => this.auth.currentUser.delete();

    doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

    doPasswordUpdate = password =>
        this.auth.currentUser.updatePassword(password);

    // *** User API ***
    user = uid => this.db.ref(`users/${uid}`);
    users = () => this.db.ref('users');
    nums = () => this.db.ref('today/bankers');

    // Use this for AuthUser or use AuthUser Helper component from ./Session
    uid = () => this.auth.currentUser.uid;

    pendingDayConfirm = uid => this.db.ref(`orders_pending_confirmation/day/${uid}`);
    pendingWeekConfirm = uid => this.db.ref(`orders_pending_confirmation/week/${uid}`);
    pendingDonateConfirm = uid => this.db.ref(`orders_pending_confirmation/donate/${uid}`);


    buyToday = pack => this.func.httpsCallable('onUserBuyToday')(pack);
    buyWeekly = pack => this.func.httpsCallable('onUserBuy')(pack);
    donate = pack => this.func.httpsCallable('onUserDonate')(pack);

    confirmDay = () => this.func.httpsCallable('onUserConfirmDayPayment')()
    confirmWeekly = () => this.func.httpsCallable('onUserConfirmWeekPayment')()
    confirmDonate = () => this.func.httpsCallable('onUserConfirmDonatePayment')()
    confirm = type => {
        switch (type) {
            case 'day':
                return this.func.httpsCallable('onUserConfirmDayPayment')()
            case 'weekly':
                return this.func.httpsCallable('onUserConfirmWeekPayment')()
            case 'donate':
                return this.func.httpsCallable('onUserConfirmDonatePayment')()

            default:
                break;
        }
    }

    ///Ed
    // payRave = pack => this.func.httpsCallable('payFlutterGhMomo')(pack) //TODO: To be removed
    payRaveDay = pack => this.func.httpsCallable('payFlutterGhDayMomo')(pack)
    payRaveWeek = pack => this.func.httpsCallable('payFlutterGhWeekMomo')(pack)
    payRaveDonate = pack => this.func.httpsCallable('payFlutterGhDonateMomo')(pack)
    verifyRave = pack => this.func.httpsCallable('verifyRave')(pack)
}
export default Firebase;